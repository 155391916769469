<script lang="ts" setup>
  import { UseBemProps } from '~/composables/Bem/BemFacetOptions'
  import useBem from '~/composables/Bem/Bem'
  import useIsMounted from '~/composables/IsMounted/IsMounted'

  interface Props extends UseBemProps {
    facets?: Array<string>
    isPlaying: boolean
  }

  const props = defineProps<Props>()
  const { bemAdd, bemFacets } = useBem('c-btn-play-toggle', props, {})
  const { isMounted } = useIsMounted()

  const rootClasses = computed<Array<string>>(() => {
    return [...bemFacets.value, bemAdd(props.isPlaying ? 'playing' : 'paused')]
  })
</script>

<template>
  <button v-if="isMounted" :class="rootClasses" class="c-btn-play-toggle">
    <span class="c-btn-play-toggle__icon" />
  </button>
</template>

<style lang="scss" scoped>
  @use '@nirazul/scss-utils' as utils;
  @use '/assets/scss/util/color/color' as col;
  @use '/assets/scss/util/transition' as trs;

  .c-btn-play-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    outline: none;
  }

  .c-btn-play-toggle__icon {
    border-style: solid;
    border-color: transparent;
    background: transparent;
    box-sizing: border-box;
    width: 0;
    height: var(--c-btn-play-toggle--sz-play);

    border-left-color: var(--c-btn-play-toggle--color);
    transition: border trs.$default;
    cursor: pointer;
  }

  .c-btn-play-toggle--paused {
    .c-btn-play-toggle__icon {
      // NOTE: Playing state (triangle)
      border-top-width: calc(var(--c-btn-play-toggle--sz-play) * 0.5);
      border-right-width: 0;
      border-bottom-width: calc(var(--c-btn-play-toggle--sz-play) * 0.5);
      border-left-width: calc(var(--c-btn-play-toggle--sz-pause));

      @include utils.has-focus() {
        border-color: transparent transparent transparent col.$variant-cyan-dark;
      }
    }
  }

  .c-btn-play-toggle--playing {
    // NOTE: Paused state (double bars)
    .c-btn-play-toggle__icon {
      border-style: double;
      border-top-width: 0;
      border-right-width: 0;
      border-bottom-width: 0;
      border-left-width: var(--c-btn-play-toggle--sz-pause);

      @include utils.has-focus() {
        border-color: transparent transparent transparent col.$variant-red-dark;
      }
    }
  }

  .c-btn-play-toggle--in-audio {
    --c-btn-play-toggle--sz-play: 40px;
    --c-btn-play-toggle--sz-pause: calc(var(--c-btn-play-toggle--sz-play) * 0.8);
  }

  .c-btn-play-toggle--in-sidebar {
    --c-btn-play-toggle--sz-play: 28px;
    --c-btn-play-toggle--sz-pause: calc(var(--c-btn-play-toggle--sz-play) * 0.8);
  }

  .c-btn-play-toggle--light {
    --c-btn-play-toggle--color: #{col.$monochrome-white};
  }

  .c-btn-play-toggle--dark {
    --c-btn-play-toggle--color: #{col.$monochrome-black};
  }
</style>
